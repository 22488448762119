import React from "react"
import PropTypes from "prop-types"

import BaseCarousel from "./BaseCarousel"
import ProcedureDetailCard from "../cards/ProcedureDetailCard"
import NewsItemDetailCard from "../cards/NewsItemDetailCard"
import TeamMemberDetailCard from "../cards/TeamMemberDetailCard"
import ConditionDetailCard from "../cards/ConditionDetailCard"

const MixedCarousel = ({
  items,
  slidesToShowXs = 1,
  slidesToShowSm = 2,
  slidesToShowMd = 3,
  slidesToShowLg = 3
}) => {
  if (items.length <= 0) {
    return <></>
  }

  const getCard = (item) => {
    switch (item.type) {
      case "procedure":
        return <ProcedureDetailCard procedure={item} showLabel />

      case "news":
        return <NewsItemDetailCard newsItem={item} showLabel />

      case "condition":
        return <ConditionDetailCard condition={item} showLabel />

      case "team_member":
        return <TeamMemberDetailCard teamMember={item} showBookButton={false} CVButtonText={`Vezi mai mult`} showLabel />

      default:
        return null
    }
  }

  return <BaseCarousel
    slidesToShowXs={slidesToShowXs}
    slidesToShowSm={slidesToShowSm}
    slidesToShowMd={slidesToShowMd}
    slidesToShowLg={slidesToShowLg}
    elements={items}
    render={item => (
      getCard(item)
    )}
  />
}

export default MixedCarousel

MixedCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  slidesToShowXs: PropTypes.number,
  slidesToShowSm: PropTypes.number,
  slidesToShowMd: PropTypes.number,
  slidesToShowLg: PropTypes.number,
}
