import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { groupBy } from "lodash"
import clsx from "clsx"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"
import LangContext from "../../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  groupList: {
    listStylePosition: "outside",
    padding: theme.spacing(0),
    listStyleType: "none",

    "& > li": {
      counterIncrement: "li1",
    },

    "& ol": {
      margin: theme.spacing(0, 1, 2, 1),
    },
  },

  groupListSubmenu: {
    minHeight: 0,
    height: 0,
    overflow: "hidden",
    transition: "all 0.2s",
    listStylePosition: "outside",
    padding: theme.spacing(0, 2),
    listStyleType: "none",
    margin: theme.spacing(0, 0, 1, 0),

    "&.isOpen": {
      minHeight: 1,
      height: "auto",
      // paddingBottom: 5,
      paddingTop: 10,
    },

    "& > li": {
      counterIncrement: "li2",
    },
  },

  groupItem: {
    "& a": {
      color: theme.palette.common.black,
    },
    "& span": {
      color: theme.palette.common.black,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
    "& span:hover": {
      color: theme.palette.common.white,
      background: theme.palette.aresBlue.main,
      cursor: "pointer",
    },
    "& a:hover": {
      color: theme.palette.aresBlue.main,
    },
  },

  groupItemSelected: {
    "& span": {
      color: theme.palette.common.white,
      background: theme.palette.aresBlue.main,
    },
  },
  groupItemSubmenu: {
    marginBottom: 10,
  },
  groupItemSubmenuSelected: {
    "& a": {
      color: theme.palette.aresBlue.main,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
  },
}))

const MenuItems = ({ locale, location, slug }) => {
  const data = useStaticQuery(query)
  const [openCateg, setOpenCateg] = useState("")

  const categories = data.procedureCategories.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node)

  useEffect(() => {
    categories.map(category => {
      if (location.pathname.match(`/${category.uid}`)) {
        return setOpenCateg(category.uid)
      }
      return null
    })
  }, [])

  const procedures = data.procedures.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node)

  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const procedureGroups = groupBy(
    procedures,
    p => p.data.categories[0].category.id
  )

  const toggleCateg = categoryUid => {
    setOpenCateg(categoryUid)
  }

  return (
    <>
      <Typography component="h2" variant="h2">
        {i18n.translations.ourProcedures}
      </Typography>
      <ul className={classes.groupList}>
        {categories.map(category => (
          <li
            key={category.id}
            className={clsx(
              classes.groupItem,
              location.pathname.match(`/${category.uid}`)
                ? classes.groupItemSelected
                : " "
            )}
          >
            <span
              onClick={() => toggleCateg(category.uid)}
              onKeyDown={() => toggleCateg(category.uid)}
              role="button"
              tabIndex="0"
            >
              {category.data.name}
            </span>
            {procedureGroups[category.prismicId] && (
              <ul
                className={`${classes.groupListSubmenu} ${openCateg === category.uid ? "isOpen" : null
                  }`}
              >
                {procedureGroups[category.prismicId].map(procedure => (
                  <li
                    key={procedure.id}
                    className={clsx(
                      classes.groupItemSubmenu,
                      location.pathname.match(procedure.uid)
                        ? classes.groupItemSubmenuSelected
                        : ""
                    )}
                  >
                    <a
                      href={`${process.env.GATSBY_BASE_URL
                        }${localizedSlugPrefix(procedure)}/${slug}/${category.uid
                        }/${procedure.uid}/`}
                    >
                      {procedure.data.title.text}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

export default MenuItems

const query = graphql`
  query ProceduresMenuQuery {
    procedureCategories: allPrismicProcedureCategory(
      sort: { order: ASC, fields: data___order_number }
    ) {
      edges {
        node {
          uid
          id
          prismicId
          lang
          data {
            name
          }
          lang
        }
      }
    }
    procedures: allPrismicProcedure(
      sort: { fields: data___order_number, order: ASC }
    ) {
      edges {
        node {
          id
          href
          lang
          uid
          data {
            title {
              text
            }
            categories {
              category {
                id
                url
                slug
              }
            }
          }
        }
      }
    }
  }
`

MenuItems.propTypes = {
  location: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}
