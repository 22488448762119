import React, { useContext } from "react"
import PropTypes from "prop-types"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import makeStyles from '@material-ui/core/styles/makeStyles'
import { useStaticQuery, graphql, Link } from "gatsby"

import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"

import LangContext from "../../contexts/LangContext"
import CustomButton from "../buttons/CustomButton"
import { CardMedia } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    padding: '2px 6px',
    borderRadius: 2,
    fontWeight: 700,
    fontSize: 12,
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 9999,
  },
  mediaWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: 'relative'
  },
  media: {
    backgroundSize: "contain",
    height: 140,
    marginBottom: theme.spacing(1),
    backgroundPosition: "center",

    [theme.breakpoints.down("sm")]: {
      height: 300,
    },

    [theme.breakpoints.down("xs")]: {
      height: 160,
    },
  },
  wrapper: {
    height: 170,
  },
  date: {
    color: "#666",
    fontSize: 16,
    marginBottom: 10,
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    maxHeight: 80,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
  },

  excerpt: {
    fontSize: 16,
    maxHeight: 76,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
  },

  button: {
    marginBottom: theme.spacing(1),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const ConditionDetailCard = ({ condition, showLabel }) => {
  const classes = useStyles()
  const lang = useContext(LangContext)

  const data = useStaticQuery(query)

  const conditionPage = data.conditionPages.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node)
    .pop()

  return (
    <Card elevation={0} square>


      {condition.data.image && (
        <div className={classes.mediaWrapper}>
          {showLabel && <span className={classes.label}>Afecțiuni</span>}
          <CardMedia>
            <GatsbyImage
              loading="eager"
              className={classes.media}
              image={condition.data.image.gatsbyImageData}
              alt={condition.data.image.alt ?? ""}
            />
          </CardMedia>
        </div>
      )}
      <div className={classes.wrapper}>
        <Typography component="div" variant="h5" className={classes.title}>
          {condition.data.title.text}
        </Typography>
        <Typography component="div" variant="body1" className={classes.excerpt}>
          {condition.data.excerpt.text}
        </Typography>
      </div>
      <br />
      <CustomButton
        color="primary"
        variant="contained"
        fullWidth
        component={Link}
        to={`/${conditionPage.uid}/${condition.uid}/`}
      >
        Vezi mai mult
      </CustomButton>
    </Card>
  )
}

export default ConditionDetailCard

const query = graphql`
  query ConditionDetailCardQuery {
    conditionPages: allPrismicConditionsPage {
      edges {
        node {
          uid
          lang
        }
      }
    }
  }
`

ConditionDetailCard.defaultProps = {
  showLabel: false,
}

ConditionDetailCard.propTypes = {
  condition: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
}
