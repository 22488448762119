import React from "react"
import PropTypes from "prop-types"

import ContactLocationCard from "../cards/ContactLocationCard"
import BaseCarousel from "./BaseCarousel"

const ContactLocationsCompactCarousel = ({ contactLocations, onSelect }) => {
  return (
    <BaseCarousel
      elements={contactLocations}
      slidesToShowXs={1}
      slidesToShowSm={2}
      slidesToShowMd={2}
      slidesToShowLg={2}
      render={contactLocation => <ContactLocationCard location={contactLocation} onSelect={onSelect} />}
    />
  )
}

export default ContactLocationsCompactCarousel

ContactLocationsCompactCarousel.propTypes = {
  contactLocations: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}
