import React, { useContext } from "react"
import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"

import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import LangContext from "../../contexts/LangContext"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    padding: '2px 6px',
    borderRadius: 2,
    fontWeight: 700,
    fontSize: 12,
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 9999,
  },
  mediaWrapper: {
    position: "relative"
  },
  media: {
    height: 250,
  },
  content: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  name: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
  },
  specialisation: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginBottom: 10,
  },

  button: {
    marginBottom: theme.spacing(1),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const TeamMemberDetailCard = ({
  teamMember,
  onSelect,
  showCVButton,
  CVButtonText,
  showBookButton,
  bookButtonText,
  showLabel
}) => {
  const classes = useStyles()
  const lang = useContext(LangContext)

  const data = useStaticQuery(query)

  const teamMemberPage = data.teamMemberPages.edges
    .map(r => r.node)
    .filter(tmp => tmp.lang === lang.locale)
    .pop()

  return (
    <Card elevation={0} square>
      <div className={classes.mediaWrapper}>
        {showLabel && <span className={classes.label}>Medici</span>}
        <CardMedia>
          <GatsbyImage
            className={classes.media}
            image={teamMember.data.profile_image.gatsbyImageData}
            alt={teamMember.data.profile_image.alt ?? ""}
          />
        </CardMedia>
      </div>

      <Typography variant="h5" className={classes.name}>
        {teamMember.data.name.text}
      </Typography>

      {teamMember.data.specialisations.filter(({ specialisation }) => specialisation.document).length > 0 && (
        <Typography variant="h5" className={classes.specialisation}>
          {
            teamMember.data.specialisations[0].specialisation.document.data
              .title.text
          }
        </Typography>
      )}
      <br />

      {showCVButton && <CustomButton
        color="primary"
        variant="contained"
        fullWidth
        className={classes.button}
        component={Link}
        to={`/${teamMemberPage.uid}/${teamMember.uid}/`}
      >
        {CVButtonText}
      </CustomButton>}

      {showBookButton && <CustomButton
        fullWidth
        size="small"
        color="secondary"
        variant="contained"
        className={classes.button}
        onClick={() => onSelect(teamMember.uid)}
      >
        {bookButtonText}
      </CustomButton>}
    </Card>
  )
}

export default TeamMemberDetailCard

const query = graphql`
  query TeamMemberDetailQuery {
    teamMemberPages: allPrismicTeam {
      edges {
        node {
          uid
          lang
        }
      }
    }
  }
`

TeamMemberDetailCard.defaultProps = {
  showCVButton: true,
  CVButtonText: "Curriculum Vitae",
  showBookButton: true,
  bookButtonText: "Programează-te acum!",
  onSelect: () => { },
  showLabel: false,
}

TeamMemberDetailCard.propTypes = {
  teamMember: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  showCVButton: PropTypes.bool,
  CVButtonText: PropTypes.string,
  showBookButton: PropTypes.bool,
  bookButtonText: PropTypes.string,
  showLabel: PropTypes.bool,
}
