import React from "react"
import PropTypes from "prop-types"

import BaseCarousel from "./BaseCarousel"
import TeamMemberDetailCard from "../cards/TeamMemberDetailCard"

const TeamMembersCarousel = ({
  teamMembers,
  onSelect,
  slidesToShowXs = 1,
  slidesToShowSm = 2,
  slidesToShowMd = 3,
  slidesToShowLg = 3
}) => (
  <BaseCarousel
    slidesToShowXs={slidesToShowXs}
    slidesToShowSm={slidesToShowSm}
    slidesToShowMd={slidesToShowMd}
    slidesToShowLg={slidesToShowLg}
    elements={teamMembers}
    render={teamMember => (
      <TeamMemberDetailCard teamMember={teamMember} onSelect={onSelect} />
    )}
  />
)

export default TeamMembersCarousel

TeamMembersCarousel.defaultProps = {
  onSelect: () => { },
}

TeamMembersCarousel.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
}
