import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ContactPopup from "../contacts/ContactPopup"
import LangContext from "../../contexts/LangContext"
import LocationContext from "../../contexts/LocationContext"

const BannerImage = ({ image, url, showContact }) => {
  const lang = useContext(LangContext)
  const location = useContext(LocationContext)
  const [contactOpen, setContactOpen] = useState(false)

  return <div className="bannerImage">
    {!showContact && (
      <Link to={url}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ""}
        />
      </Link>
    )}

    {showContact && (
      <div
        onClick={() => setContactOpen(true)}
        style={{ cursor: "pointer" }}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ""}
        />
      </div>
    )}

    <ContactPopup
      locale={lang.locale}
      location={location}
      open={contactOpen}
      handleClose={() => setContactOpen(false)}
    />
  </div>
}

export default BannerImage

BannerImage.propTypes = {
  image: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  showContact: PropTypes.bool.isRequired,
}
