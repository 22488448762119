import React, { useContext } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import makeStyles from '@material-ui/core/styles/makeStyles'

import { useStaticQuery, graphql, Link } from "gatsby"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import LangContext from "../../contexts/LangContext"

import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  media: {
    height: 240,

    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
  content: {
    background: theme.palette.primary.main,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),

      "&:last-child": {
        paddingBottom: theme.spacing(0),
      },
    },
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 22,
    lineHeight: "38px",
    fontWeight: 700,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  actions: {
    flexDirection: "column",
    padding: 0,
  },
  button: {
    marginBottom: theme.spacing(1),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const ContactLocationCard = ({ location, onSelect }) => {
  const classes = useStyles()
  const lang = useContext(LangContext)

  const data = useStaticQuery(query)

  const contactPage = data.contactPages.edges
    .map(r => r.node)
    .filter(tmp => tmp.lang === lang.locale)
    .pop()

  return (
    <Card elevation={0} square className={classes.locationBox}>
      <CardActionArea>
        <Link
          to={`${localizedSlugPrefix(location)}/${contactPage.uid}/${location.uid}/`}
        >
          <CardMedia>
            <GatsbyImage
              loading="eager"
              className={classes.media}
              image={location.data.gallery[0].image.gatsbyImageData}
              alt={`MONZA ARES ${location.data.city.text}`}
            />
          </CardMedia>
          <CardContent className={classes.content}>
            <Typography variant="h5" className={classes.title}>
              {location.data.title.text}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
      <CardActions className={classes.actions} disableSpacing>
        <CustomButton
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          className={classes.button}
          component={Link}
          to={`${localizedSlugPrefix(location)}/${contactPage.uid}/${location.uid}/`}
        >
          Vizitează spitalul
        </CustomButton>

        <CustomButton
          size="small"
          variant="contained"
          fullWidth
          color="secondary"
          className={classes.button}
          onClick={() => onSelect(location.uid)}
        >
          Fă-ți o programare!
        </CustomButton>
      </CardActions>
    </Card>
  )
}

export default ContactLocationCard

const query = graphql`
  query ContactLocationCardQuery {
    contactPages: allPrismicContact {
      edges {
        node {
          lang
          uid
        }
      }
    }
  }
`

ContactLocationCard.propTypes = {
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}
