export default function (schema, data) {
  let parsedSchema =
    (schema && schema.length && typeof schema !== "object")
      ? JSON.parse(schema.replace(/(\r\n|\n|\r)/gm, ""))
      : (schema && typeof schema === "object" ? schema : {})

  if (!parsedSchema["@graph"]) {
    parsedSchema["@graph"] = []
  }

  parsedSchema["@graph"].push(data)

  return JSON.stringify(parsedSchema)
}
